import formatString from 'format-string-by-pattern';

const pattern = {
  cpf: 'XXX.XXX.XXX-XX',
  cnpj: 'XX.XXX.XXX/XXXX-XX',
};

const formatCpfCnpj = (cpfcnpj) => {
  if (cpfcnpj.length === 14) {
    return formatString(pattern.cnpj, cpfcnpj);
  }
  return formatString(pattern.cpf, cpfcnpj);
};

export default formatCpfCnpj;
