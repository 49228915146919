var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        [
          _c(
            "v-flex",
            { staticClass: "ma-3", attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "mb-2", attrs: { xs12: "" } },
                            [
                              _c("span", { staticClass: "title mb-2" }, [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.user.name) +
                                    "\n        "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "mb-1", attrs: { xs12: "" } },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n              CPF: " +
                                    _vm._s(_vm.formatCpfCnpj(_vm.user.cpf)) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "mb-1", attrs: { xs12: "" } },
                            [
                              _c("span", { staticClass: "text-lowercase" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.user.email) +
                                    "\n            "
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "" } },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs4: "" } },
                                    [
                                      _c("span", [_vm._v(" Senha: *********")]),
                                      _c("change-password"),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }