<template>
  <v-container>
    <v-layout>
      <v-flex class="ma-3" xs12 >
        <v-card>
          <v-card-text>
            <v-layout wrap>
              <v-flex class="mb-2" xs12>
          <span class="title mb-2">
            {{ user.name }}
          </span>
              </v-flex>
              <v-flex class="mb-1" xs12>
              <span>
                CPF: {{ formatCpfCnpj(user.cpf) }}
              </span>
              </v-flex>
              <v-flex class="mb-1" xs12>
              <span class="text-lowercase">
                {{ user.email }}
              </span>
              </v-flex>

              <v-flex xs12>
                <v-layout>
                  <v-flex xs4>
                    <span> Senha: *********</span> <change-password/>
                  </v-flex>
                  <v-spacer></v-spacer>
                </v-layout>

              </v-flex>

            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import ChangePassword from '@/components/user/ChangePassword.vue';

export default {
  name: 'Account',
  components: { ChangePassword },
  data() {
    return {
      valid: false,
      formatCpfCnpj,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/currentUser'].user;
    },
  },
};
</script>
