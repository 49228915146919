<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="650px"
  >
    <v-card>
      <v-card-title class="headline green darken-2 white--text">
        <v-layout align-center justify-space-between>
          Cadastro de entidade
          <v-btn flat icon @click="close">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-layout>
      </v-card-title>

      <v-card-text class="py-0">
        <create-entity-form
          :formEntity="formEntity"
          @formIsValid="entityFormIsValid"
        />
      </v-card-text>

      <v-card-actions>
        <v-layout justify-end class="mr-4">
          <v-btn flat @click="close">
            Cancelar
          </v-btn>

          <v-btn
            color="primary"
            :disabled=" !entityFormValid || entityAlreadyRegistred"
            :loading="loading"
            @click="save()"
          >
            Confirmar
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CreateEntityForm from '@/components/person/CreateEntityForm.vue';
import validationRules from '@/mixins/validation-rules';
import getAddressByCEP from '@/utils/getAddressByCEP';
import personService from '@/services/person-service';
import masks from '@/utils/masks';
import { mapActions } from 'vuex';

export default {
  name: 'create-entity-dialog',
  data() {
    return {
      validationRules,
      getAddressByCEP,
      masks,
      dialog: false,
      loading: false,
      entityFormValid: false,
      entityAlreadyRegistred: false,
      formEntity: { postalCode: '', cpf_cnpj: '', profile: [] },
    };
  },
  mixins: [validationRules],
  components: {
    CreateEntityForm,
  },
  computed: {
    currentUserCPF() {
      return this.$store.getters['auth/currentUser'].user.cpf;
    },
  },
  methods: {
    ...mapActions({
      toggleSnackbar: 'application/toggleSnackbar',
      updatePeopleList: 'person/setOffices',
    }),
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.formEntity = { postalCode: '', cpf_cnpj: '' };
    },
    save() {
      const payload = {
        cpf: this.currentUserCPF,
        name: this.formEntity.name,
        cpf_cnpj: this.formEntity.cpf_cnpj,
        address_attributes: {
          zip_code: this.formEntity.postalCode,
          street: this.formEntity.street,
          number: this.formEntity.number,
          neighborhood: this.formEntity.neighbourhood,
          complement: this.formEntity.address_details,
          city_id: this.formEntity.city.id,
          federal_unit_id: this.formEntity.state.id,
        },
        profile: this.formEntity.profile,
      };
      this.loading = true;

      personService
        .createPerson(payload)
        .then(({ data }) => {
          this.toggleSnackbar({
            show: true,
            timeout: 6000,
            color: 'success',
            message: 'Entidade cadastrada com sucesso!',
          });
        })
        .catch((err) => {
          this.toggleSnackbar({
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          this.updatePeopleList();
          this.loading = false;
          this.close();
        });
    },
    entityFormIsValid(value) {
      this.entityFormValid = value;
    },
    getValidationRulesForCpfCnpj(cpfCnpj) {
      const rules = [];
      rules.push(cpfCnpj.length < 12 ? validationRules.cpf : validationRules.cnpj);
      return rules;
    },
    getMask(cpfCnpj) {
      return cpfCnpj.length < 12 ? '###.###.###-###' : '##.###.###/####-##';
    },
  },
};
</script>

<style>
  .profile label {
    font-size: 14px !important;
  }
</style>
