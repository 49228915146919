var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  { attrs: { color: "green darken-1", dark: "", small: "" } },
                  on
                ),
                [_vm._v("\n      Alterar\n    ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline green darken-1 white--text",
                  attrs: { "primary-title": "" },
                },
                [_vm._v("\n        Alterar Senha\n      ")]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs8: "" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Nova Senha",
                              "append-icon": _vm.hidePass
                                ? "mdi-eye"
                                : "mdi-eye-off",
                              type: _vm.hidePass ? "password" : "text",
                              rules: [
                                _vm.validationRules.required,
                                _vm.validationRules.passwordMinLength,
                              ],
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.hidePass = !_vm.hidePass
                              },
                            },
                            model: {
                              value: _vm.password,
                              callback: function ($$v) {
                                _vm.password = $$v
                              },
                              expression: "password",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "red", flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("\n          Fechar\n        ")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        flat: "",
                        loading: _vm.loading,
                        disabled: !_vm.valid,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changePassword()
                        },
                      },
                    },
                    [_vm._v("\n          Alterar Senha\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }