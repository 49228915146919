var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-layout",
        { attrs: { row: "", "align-end": "" } },
        [
          _c(
            "v-flex",
            { staticClass: "ma-3", attrs: { xs9: "" } },
            [
              _c(
                "v-layout",
                { attrs: { column: "", "align-start": "" } },
                [
                  _c("v-flex", { attrs: { xs1: "" } }, [
                    _c("h3", [_vm._v("Entidade: ")]),
                  ]),
                  _c(
                    "v-flex",
                    { attrs: { xs6: "" } },
                    [
                      _c("v-select", {
                        staticClass: "select-entity elevation-3",
                        attrs: {
                          label: "Selecione uma Entidade",
                          "append-icon": "mdi-chevron-down",
                          "no-data-text": "Nenhuma Entidade encontrada",
                          "single-line": "",
                          items: _vm.offices,
                          "return-object": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (data) {
                              return [
                                _c(
                                  "v-list-tile-content",
                                  [
                                    _c("v-list-tile-title", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.formatCpfCnpj(
                                              data.item.cpf_cnpj
                                            )
                                          ) +
                                          " - " +
                                          _vm._s(data.item.name) +
                                          "\n                "
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "selection",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.formatCpfCnpj(data.item.cpf_cnpj)
                                    ) +
                                    " - " +
                                    _vm._s(data.item.name) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedEntity,
                          callback: function ($$v) {
                            _vm.selectedEntity = $$v
                          },
                          expression: "selectedEntity",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs3: "" } },
            [
              _c(
                "v-layout",
                {
                  staticClass: "mr-3 mb-3",
                  attrs: { row: "", "justify-end": "" },
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pa-4",
                      attrs: { color: "primary" },
                      on: { click: _vm.openCreateEntityDialog },
                    },
                    [_vm._v("\n          Cadastrar Entidade\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("create-entity-dialog", { ref: "createEntityDialog" }),
      !_vm.isEmpty(_vm.selectedEntity)
        ? [
            _vm.selectedEntity.status === "not_approved"
              ? _c(
                  "v-layout",
                  {
                    staticClass: "px-3",
                    attrs: { column: "", "align-start": "" },
                  },
                  [
                    _c(
                      "v-card",
                      { staticClass: "white--text", attrs: { color: "red" } },
                      [
                        _c("v-card-title", { staticClass: "py-2" }, [
                          _c("div", [
                            _c("div", { staticClass: "subheading" }, [
                              _vm._v("CADASTRO RECUSADO"),
                            ]),
                            _c("span", { staticClass: "font-weight-regular" }, [
                              _vm._v("Liberado para editar e enviar novamente"),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "my-2" }, [
                      _c("b", [_vm._v("Motivo da recusa:")]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.selectedEntity.reason) +
                          "\n      "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedEntity.status === "waiting_approval"
              ? _c(
                  "v-layout",
                  {
                    staticClass: "px-3",
                    attrs: { column: "", "align-start": "" },
                  },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "white--text",
                        attrs: { color: "primary" },
                      },
                      [
                        _c("v-card-title", { staticClass: "py-2" }, [
                          _c("div", [
                            _c("div", { staticClass: "subheading" }, [
                              _vm._v("AGUARDANDO APROVAÇÃO"),
                            ]),
                            _c("span", { staticClass: "font-weight-regular" }, [
                              _vm._v(
                                "Os dados inseridos estão sendo analizados"
                              ),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-layout",
              _vm._l(_vm.cards, function (card, index) {
                return _c(
                  "v-flex",
                  { key: card.title, attrs: { xs3: "" } },
                  [
                    _c(
                      "v-card",
                      {
                        class: [{ active: _vm.activeCard === index }, "ma-3"],
                        on: {
                          click: function ($event) {
                            return _vm.setActiveCard(index)
                          },
                        },
                      },
                      [
                        _c("v-card-title", { attrs: { "primary-title": "" } }, [
                          _c(
                            "div",
                            { staticClass: "icon-card" },
                            [
                              _c(
                                "v-layout",
                                [
                                  _c("v-img", {
                                    attrs: { src: _vm.getIconUrl(card.icon) },
                                  }),
                                  card.pending
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "alert-icon",
                                          attrs: {
                                            small: "",
                                            color: "red darken-1",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " mdi-alert-circle\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-3" }, [
                            _c("div", { staticClass: "headline" }, [
                              _vm._v(_vm._s(card.title)),
                            ]),
                            _c("span", [_vm._v(_vm._s(card.subtitle))]),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "v-layout",
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "", "ma-3": "" } },
                  [
                    _c(
                      "keep-alive",
                      [
                        _c(_vm.cards[_vm.activeCard].component, {
                          tag: "component",
                          attrs: { selectedEntity: _vm.selectedEntity },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }