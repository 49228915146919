<template>

  <v-container>
    <v-layout row align-end>
      <v-flex class="ma-3" xs9>
        <v-layout column align-start>
          <v-flex xs1>
            <h3>Entidade: </h3>
          </v-flex>
          <v-flex xs6>
            <v-select
              v-model="selectedEntity"
              class="select-entity elevation-3"
              label="Selecione uma Entidade"
              append-icon="mdi-chevron-down"
              no-data-text="Nenhuma Entidade encontrada"
              single-line
              :items="offices"
              return-object>
              <template
                v-slot:item="data">
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{formatCpfCnpj(data.item.cpf_cnpj)}} - {{data.item.name}}
                  </v-list-tile-title>
                </v-list-tile-content>
              </template>

              <template
                v-slot:selection="data">
                    {{formatCpfCnpj(data.item.cpf_cnpj)}} - {{data.item.name}}
              </template>
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs3>
        <v-layout row justify-end class="mr-3 mb-3">
          <v-btn color="primary" class="pa-4" @click="openCreateEntityDialog">
            Cadastrar Entidade
          </v-btn>
        </v-layout>
      </v-flex>
    </v-layout>

    <create-entity-dialog ref="createEntityDialog"/>

    <template v-if="!isEmpty(selectedEntity)">
      <v-layout column align-start class="px-3" v-if="selectedEntity.status === 'not_approved'">
        <v-card color="red" class="white--text">
          <v-card-title class="py-2">
            <div>
              <div class="subheading">CADASTRO RECUSADO</div>
              <span class="font-weight-regular">Liberado para editar e enviar novamente</span>
            </div>
          </v-card-title>
        </v-card>

        <div class="my-2">
          <b>Motivo da recusa:</b>
          {{ selectedEntity.reason }}
        </div>
      </v-layout>

      <v-layout column align-start class="px-3" v-if="selectedEntity.status === 'waiting_approval'">
        <v-card color="primary" class="white--text">
          <v-card-title class="py-2">
            <div>
              <div class="subheading">AGUARDANDO APROVAÇÃO</div>
              <span class="font-weight-regular">Os dados inseridos estão sendo analizados</span>
            </div>
          </v-card-title>
        </v-card>
      </v-layout>

      <v-layout>
        <v-flex xs3 v-for="(card, index) in cards" :key="card.title">
          <v-card
            :class="[{active: activeCard === index}, 'ma-3']"
            @click="setActiveCard(index)">
            <v-card-title primary-title>
              <div class="icon-card">
                <v-layout>
                  <v-img :src="getIconUrl(card.icon)"></v-img>
                  <v-icon
                    v-if="card.pending"
                    class="alert-icon"
                    small
                    color="red darken-1"
                  > mdi-alert-circle
                  </v-icon>
                </v-layout>
              </div>

              <div class="ml-3">
                <div class="headline">{{card.title}}</div>
                <span>{{card.subtitle}}</span>
              </div>
            </v-card-title>

          </v-card>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs12 ma-3>
          <keep-alive>
            <component v-bind:is="cards[activeCard].component" :selectedEntity="selectedEntity"/>
          </keep-alive>
        </v-flex>
      </v-layout>
    </template>

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, filter } from 'lodash';
import masks from '@/utils/masks';
import validationRules from '@/mixins/validation-rules';
import formatCpfCnpj from '@/utils/formatCpfCnpj';
import CreateEntityDialog from './CreateEntityDialog.vue';

export default {
  name: 'Person',
  components: {
    CreateEntityDialog,
  },
  beforeMount() {
    _.extend(this.formData, this.currentOfficeData);
    this.$store.dispatch('person/setOffices');
  },
  data() {
    return {
      formatCpfCnpj,
      activeCard: 0,
      isEmpty,
      filter,
      masks,
      validationRules,
      selectedEntity: {},
      cards: [
        {
          title: 'Entidade',
          subtitle: 'Dados da Entidade',
          icon: 'briefcase.svg',
          pending: false,
          component: () => import('@/components/person/PersonInfo.vue'),
        },
        {
          title: 'Funcionários',
          subtitle: 'Pessoas Vinculadas',
          icon: 'business-card.svg',
          pending: false,
          component: () => import('@/components/person/PersonEmployees.vue'),
        },
        {
          title: 'Licenças',
          subtitle: 'Licenças da Entidade',
          icon: 'document.svg',
          pending: false,
          component: () => import('@/components/person/PersonLicenses.vue'),
        },
        {
          title: 'Modelos',
          subtitle: 'Modelos de MTR',
          icon: 'file.svg',
          pending: false,
          component: () => import('@/components/person/PersonMtrModels.vue'),
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchPerson: 'person/fetchPerson',
      fetchEmployees: 'person/fetchEmployees',
    }),
    setActiveCard(activeCardIndex) {
      if (this.selectedEntity.status !== 'approved') return;
      this.activeCard = activeCardIndex;
    },
    getIconUrl(icon) {
      return require(`@/assets/person_cards/${icon}`); // eslint-disable-line
    },
    async fetchSelectedEntityData(personId) {
      await this.fetchPerson(personId);
      await this.fetchEmployees(personId);
      this.cards[2].pending = this.pendingLicenses;
    },
    openCreateEntityDialog() {
      this.$refs.createEntityDialog.open();
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      offices: 'person/offices',
      employees: 'person/employees',
      person: 'person/person',
    }),
    pendingLicenses() {
      if (this.person.licenses) {
        const pendingLicenses = this.person.licenses.filter(license => license.status !== 'approved');
        return pendingLicenses.length > 0;
      }
      return false;
    },
  },
  watch: {
    selectedEntity(person) {
      this.fetchSelectedEntityData(person.id);
    },
  },
};
</script>
<style lang="scss">
  .icon-card{
    width: 44px;

    .alert-icon{
      position: absolute;
      border-radius: 15px;
      background: #fff;
    }
  }

  .select-entity {

    &.v-text-field{
      padding: 0 !important;
    }

    .v-label{
      position: initial !important;
      margin-left: 10px;
    }

    .v-select__selections{
      padding: 10px;
    }

    .v-input__append-inner{
      margin-top: 15px;
    }

    .v-input__slot:before{
      border: none !important;
    }

    .v-input__slot{
      margin: 0;
    }

    input{
      margin-left: 10px;
    }

    .v-text-field__details{
      display: none;
    }
  }

  .v-card{
    &.active{
      border-bottom: solid 6px #76C576;
    }
  }

</style>
