<template>
  <in-layout>
    <v-container slot="list">

      <account-info/>
      <person/>

    </v-container>
</in-layout>
</template>

<script>
import InLayout from '@/layouts/InLayout.vue';
import AccountInfo from '@/components/user/Account';
import Person from '@/components/person/Person';

import { indexOf } from 'lodash';

export default {
  name: 'User',
  components: {
    InLayout,
    AccountInfo,
    Person,
  },
  computed: {
    showLicensesTab() {
      const { person } = this.user;
      return indexOf(person.profile, 'transporter') !== -1 ||
      indexOf(person.profile, 'receiver') !== -1 ||
      indexOf(person.profile, 'temporaryStorage') !== -1;
    },
    user() {
      return this.$store.getters['auth/currentUser'];
    },
  },
};
</script>
