<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="green darken-1"
        dark
        small
        v-on="on"
      >
        Alterar
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="valid">
        <v-card-title
          class="headline green darken-1 white--text"
          primary-title
        >
          Alterar Senha
        </v-card-title>

        <v-card-text>
          <v-layout justify-center>
            <v-flex xs8>
              <v-text-field
                v-model="password"
                label="Nova Senha"
                :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="hidePass ? 'password' : 'text'"
                :rules="[validationRules.required, validationRules.passwordMinLength]"
                @click:append="hidePass = !hidePass"
              />
            </v-flex>
          </v-layout>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="red"
            flat
            @click="close()"
          >
            Fechar
          </v-btn>
          <v-spacer/>
          <v-btn
            color="primary"
            flat
            :loading="loading"
            :disabled="!valid"
            @click="changePassword()"
          >
            Alterar Senha
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>

import userService from '@/services/user-service';
import validationRules from '@/mixins/validation-rules';

export default {
  name: 'ChangePassword',
  data() {
    return {
      dialog: false,
      valid: false,
      password: '',
      loading: false,
      hidePass: true,
      validationRules,
    };
  },
  methods: {
    changePassword() {
      this.loading = true;
      userService
        .updatePassword(this.password)
        .then(() => {
          this.dialog = false;
          this.showMessage('success', 'Senha alterada com Sucesso');
        })
        .catch(() => {
          this.showMessage('error', 'Erro ao Atualizar senha');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
    },
    showMessage(type, message) {
      this.$store.dispatch('application/toggleSnackbar', {
        show: true,
        timeout: 6000,
        color: type,
        message,
        messageIcon: 'mdi-alert-outline',
      });
    },
  },
  computed: {
    user() {
      return this.$store.getters['auth/currentUser'].user;
    },
  },
};
</script>
