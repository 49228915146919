var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "650px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            { staticClass: "headline green darken-2 white--text" },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-space-between": "" } },
                [
                  _vm._v("\n        Cadastro de entidade\n        "),
                  _c(
                    "v-btn",
                    { attrs: { flat: "", icon: "" }, on: { click: _vm.close } },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-0" },
            [
              _c("create-entity-form", {
                attrs: { formEntity: _vm.formEntity },
                on: { formIsValid: _vm.entityFormIsValid },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-layout",
                { staticClass: "mr-4", attrs: { "justify-end": "" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { flat: "" }, on: { click: _vm.close } },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        disabled:
                          !_vm.entityFormValid || _vm.entityAlreadyRegistred,
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.save()
                        },
                      },
                    },
                    [_vm._v("\n          Confirmar\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }